<template>
  <v-container fluid>
    <div>
      <!-- le workflow applicatif -->
      <div class="d-flex justify-center" flat tile>
        <Workflow
          width="600"
          height="180"
          :steps="workflowSteps"
          :currentStep="workflowIndex"
          :labelWidth="200"
          :lineWidth="140"
        ></Workflow>
      </div>

      <v-row justify="center">
        <v-col xl="6" lg="8" md="8" sm="10" xs="12">
          <!-- le titre et le bouton retour -->
          <div class="d-flex justify-center">
            <TitleAndReturnComponent title="Expertise médicale" />
          </div>

          <!-- l'affichage de la table -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row no-gutters justify="space-between">
                <div>Ajouter une expertise médicale</div>
              </v-row>
            </v-card-title>
            
            <!-- le nom -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">nom</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field dense v-model="name"></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- La profession médicale -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">profession médicale</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-autocomplete
                    :items="professions"
                    v-model="selectedProfession"
                    item-text="label"
                    item-value="codeMos"
                    no-data-text="aucune profession"
                    return-object
                  ></v-autocomplete>
                </v-col>
              </v-row>   
              <v-divider></v-divider>           
            </v-card-text>

            <!-- La spécialité médicale -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">spécialité médicale</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-autocomplete
                    :items="availableSpecialities"
                    v-model="selectedSpeciality"
                    item-text="digitalName"
                    item-value="digitalName"
                    no-data-text="aucune spécialité"
                    return-object
                  ></v-autocomplete>
                </v-col>
              </v-row>              
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                class="ma-2 px-4 btn"
                color="primary"
                :disabled="!canSave"
                @click="save"
                >Enregistrer</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";

import { MedicalExpertiseService } from "@/service/practitioners/medical_expertise_service.js"
import { MedicalSpecialtiesService } from "@/service/practitioners/medical_specialties_service.js";
import { MedicalProfessionService } from "@/service/practitioners/medical_profession_service.js";

import * as exceptions from "@/service/exception_to_message.js";

import * as Logger from "@/tools/logger.js"

export default {
  name: "AddMedicalExpertise",
  components: { Workflow, TitleAndReturnComponent },

  mixins: [WorkflowMixin, RoleMixin, TableViewEditWorkflowMixin, SnackBarMixin],
  data() {
    return {
      /** requète en cours */
      loading: false,

      /** le service de la vue */
      service: null,
      serviceMedicalSpeciality: null,
      serviceMedicalProfession: null,

      /** le nom */
      name: null,

      /** la liste des profession médicale */
      professions: [],
      /** la liste des spécialité médicales */
      specialities: [],

      /** La profession sélectionnée */
      selectedProfession: null,
      /** La spécialité sélectionnée */
      selectedSpeciality: null,
      
    };
  },
  methods: {

    async load() {
      try {
        this.loading = true;

        // Récupère toutes les profession médicales
        this.professions = await this.serviceMedicalProfession.getAll();
        // Récupère toutes les spécialité médicales
        this.specialities = await this.serviceMedicalSpeciality.getAll();

      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    async save() {
      try {
        this.loading = true;

        let entity = {};
        entity.name = this.name;
        entity.medicalProfessionCodeMos = this.selectedProfession.codeMos;

        entity.medicalSpecialtyId = null;
        if (this.selectedSpeciality) {
          entity.medicalSpecialtyId = this.selectedSpeciality.id
        }

        Logger.debug("entity to save ", entity);
        // Création du de l'expertise
        await this.service.create(entity);

        this.$router.go(-1);
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "ajouter : " + (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },    
  },
  computed: {
    completed() {
      if (!this.name) return false;
      if (!this.selectedProfession) return false;

      // Condition : si la profession sélectionnée est associée à des spécialité médicales, 
      // le choix de la spécialité médicale devient obligatoire
      if (this.availableSpecialities.length > 0 && !this.selectedSpeciality) return false;

      return true;
    },

    canSave() {
      return this.completed;
    },

    // Retourne les spécialités disponibles en fonction de la profession sélectionnée
    availableSpecialities() {
      let available = [];

      if (this.selectedProfession) {
        available = this.specialities.filter((s) => s.medicalProfessionId == this.selectedProfession.codeMos);
      }      

      return available;
    },

  },
  mounted() {
    // Instanciation des services
    this.service = new MedicalExpertiseService(this.$api.getMedicalExpertisesApi());
    this.serviceMedicalSpeciality = new MedicalSpecialtiesService(
      this.$api.getMedicalSpecialitiesApi());
    this.serviceMedicalProfession = new MedicalProfessionService(this.$api.getMedicalProfessionApi());

    // Initialisation du worflow
    this.initTableViewEditWorkflow(this.canEdit, 1);

    this.load();
  },
};
</script>

<style>
</style>